import React, { useState, Fragment } from 'react'
import { graphql, navigate } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'
// import { Link } from 'gatsby-plugin-intl-v6'
import ExpandableCard from '../components/docs/expandableCard'
import Header from '../components/header'
import Footer from '../components/footer'
import Seo from '../components/seo'
import H2 from '../customMdx/h2'
import H3 from '../customMdx/h3'
import H4 from '../customMdx/h4'
import H5 from '../customMdx/h5'
import H6 from '../customMdx/h6'
import Codeblock from '../customMdx/codeblock'

const components = {
  Link,
  ExpandableCard,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  pre: Codeblock
} // Provide common components here

const DirectorsPage = ({ data: { allMdx } }: any) => {
  console.log(allMdx)
  const content = allMdx.edges[0].node
  const tableOfContents = content.tableOfContents.items
  const intl = useIntl()

  const handleGoBack = () => {
    if (typeof window !== 'undefined' && window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/'); // 退回首页
    }
  };

  return (
    <>
      <Seo title={content.frontmatter.title} description={content.frontmatter.description} meta={[]} lang={content.frontmatter.language} />
      <div>
        <div className='mediareport-detail'>
          <Header />
          <div className={'container community-side'}>
            <p className='go-back container' onClick={handleGoBack}>
            &lt; {intl.formatMessage({ id: 'Back to list' })}
            </p>
            <p className='go-back-mobile' onClick={handleGoBack}>
            &lt; {intl.formatMessage({ id: 'Back to list' })}
            </p>
            <h2>{ content.frontmatter.title }</h2>
            
            <div className={'side-list'}>
              <MDXProvider components={components}>
                <MDXRenderer>{content.body}</MDXRenderer>
              </MDXProvider>
            </div>

            <div className='link-box'>
                <a href={ content.frontmatter.link } target='_blank'>{intl.formatMessage({ id: 'News article link' })} &gt;</a>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </>
  )
}
// tableOfContents(maxDepth: 2)
export const query = graphql`
  query ($slug: String!, $language: String!) {
    allMdx(filter: { slug: { eq: $slug }, frontmatter: { language: { eq: $language } } }) {
      edges {
        node {
          rawBody
          body
          slug
          frontmatter {
            title
            language
            description
            link
          }
          tableOfContents
        }
      }
    }
  }
`
export default DirectorsPage
